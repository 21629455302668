import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, values, up: above },
  } = theme;
  return {
    // Layouts 1 and 2
    main_container_layout_1_and_2: {
      width: "100% !important",
      flexWrap: "nowrap !important",
      backgroundColor: "white",
      margin: "0px !important",
      padding: `0px ${spacing(5)}px`,
      [below(1025)]: {
        flexDirection: "column",
        padding: 0,
      },
    },
    layout_container_2: {
      [above(1025)]: {
        height: spacing(88.125),
        paddingLeft: "0px !important",
        paddingRight: spacing(3) + "px !important",
      },
      gap: spacing(3),
    },
    main_story_container_layout_2: {
      padding: "0px !important",
    },
    secondary_stories_container_layout_1: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [below(1025)]: {
        flexDirection: "row",
        columnGap: spacing(4),
        alignItems: "center",
      },
      [below(values.sm)]: {
        flexDirection: "column",
      },
    },
    secondary_stories_container_layout_2: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "end",
      padding: "0px !important",

      [below(1025)]: {
        flexDirection: "row",
        alignItems: "center",
        gap: spacing(9),
        paddingTop: spacing(3),
        paddingLeft: spacing(1) + "px !important",
      },
      [below(values.sm)]: {
        flexDirection: "column-reverse",
      },
    },
  };
});

export { useStyles };
