import React, { useMemo } from "react";
import Grid from "../../../../../common/Grid/Grid";
import VerticalCard from "../../VerticalCard/VerticalCard";
import VideoAd from "../../../../Ad/VideoAd";
import AdCard from "../../../../Homepage/AdCard/AdCard";
import SecondaryStoriesContainer from "../../SecondaryStoriesContainer/SecondaryStoriesContainer";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import { useTown } from "../../../../../context/TownContext";
import { useStyles } from "./Layout1and2.style";
import clsx from "clsx";

const Layout1and2 = ({
  adTargets,
  videoAd,
  localNews,
  hasLockedBullseyeAd,
}) => {
  const classes = useStyles();
  const isMediumScreen = screenWidthIsLowerThan(1025);
  const isLargeScreen = screenWidthIsLowerThan(1225);
  const { topStoriesTemplate } = useTown();

  const { mainStory, secondaryStories } = localNews || {};

  const spacingForLayout = useMemo(() => {
    if (topStoriesTemplate === "layout_1") {
      return isLargeScreen ? 3 : 10;
    }
    return isMediumScreen ? 0 : 5;
  }, [isLargeScreen, isMediumScreen]);

  if (hasLockedBullseyeAd === null) return null;

  return (
    <Grid
      container
      spacing={spacingForLayout}
      classes={{
        root: clsx(
          classes.main_container_layout_1_and_2,
          topStoriesTemplate === "layout_2" && classes.layout_container_2
        ),
      }}
    >
      <Grid
        item
        xs={12}
        md={isMediumScreen ? 12 : 8}
        lg={8}
        classes={{
          root: classes[`main_story_container_${topStoriesTemplate}`],
        }}
      >
        <VerticalCard variant={topStoriesTemplate} contents={mainStory} />
      </Grid>

      <Grid
        item
        xs={12}
        md={isMediumScreen ? 12 : 4}
        lg={4}
        classes={{
          root: classes[`secondary_stories_container_${topStoriesTemplate}`],
        }}
      >
        {videoAd && Object.keys(videoAd).length > 0 ? (
          <VideoAd videoAd={videoAd} />
        ) : (
          <AdCard
            adTargets={adTargets}
            adSize={[300, 250]}
            adUnit={
              hasLockedBullseyeAd ? "locked_bullseye" : "tap_bullseye_001"
            }
            options={{
              forTopStories: true,
            }}
          />
        )}
        {secondaryStories?.length > 0 && (
          <SecondaryStoriesContainer
            stories={secondaryStories}
            variant={topStoriesTemplate}
            options={{
              truncatedHeader:
                topStoriesTemplate === "layout_2" && isMediumScreen,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Layout1and2;
