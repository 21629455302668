import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, values },
  } = theme;

  return {
    // Layout 1
    container_layout_1: {
      flexDirection: "column",
      padding: `${spacing(2)}px 0px`,
      rowGap: spacing(3),
    },

    // Layout 2
    container_layout_2: {
      flexDirection: "column",
      rowGap: spacing(1),
      flexWrap: "nowrap !important",
      [below(1033)]: {
        paddingRight: spacing(2),
      },
      [below(values.sm)]: {
        width: "100% !important",
        padding: `0px ${spacing(1)}px`,
      },
    },

    // Layout 5
    container_layout_5: {
      flexDirection: "column",
    },
  };
});

export { useStyles };
