import { makeStyles } from "../../../../common/Theme/Theme";

// TODO: Refactor these styles

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { down: below, values, up },
  } = theme;

  const defaultStyles = {
    trucated_text: {
      textOverflow: "ellipsis",
      display: "box",
      boxOrient: "vertical",
      overflow: "hidden",
      textAlign: "justify",
      height: "fit-content",
    },
    header_container: {
      height: "fit-content",
    },
    image: {
      height: "auto",
      maxHeight: "100%",
    },
    media_container: {
      backgroundColor: palette.light.imageBackground,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    container: {
      flexDirection: "column",
      height: "100%",
    },
  };

  return {
    // Layout 1
    header_layout_1: {
      "& span": {
        fontSize: "40px !important",
        fontFamily: "PT Serif, serif !important",
        fontWeight: "700 !important",
        lineHeight: "normal !important",
      },
      "& span:hover": {
        textDecoration: "underline !important",
      },
    },
    header_container_truncated_layout_1: {
      ...defaultStyles.trucated_text,
      lineClamp: 2,
    },
    header_container_layout_1: {
      ...defaultStyles.header_container,
    },
    image_layout_1: {
      ...defaultStyles.image,
    },
    image_link_layout_1: {
      height: "100%",
    },
    media_container_layout_1: {
      ...defaultStyles.media_container,
      height: "70%",
      [below(1025)]: {
        height: "40vh",
      },
    },
    container_layout_1: {
      ...defaultStyles.container,
      rowGap: spacing(2),
    },

    // Layout 2
    jumbotron: {
      aspectRatio: "16/9 !important",
      backgroundPosition: "center !important",
      backgroundSize: "contain !important",
      [below(1025)]: {
        height: "35vh !important",
      },
    },
    header_layout_2: {
      "& h1": {
        [below(values.md)]: {
          fontSize: "16px !important",
        },
      },
      "& h1:hover": {
        textDecoration: "underline !important",
      },
    },
    tag_layout_2: {
      textAlign: "center",
      backgroundColor: "black",
      color: "white",
      textTransform: "uppercase",
      letterSpacing: "1.2px",
      padding: `${spacing(0.5)}px ${spacing(1)}px`,
      borderRadius: "3px",
      "& a > span": {
        fontFamily: "Bebas Neue, sans-serif !important",
        fontSize: "24px !important",
        fontWeight: "400 !important",
        [below(values.md)]: {
          fontSize: "20px !important",
        },
      },

      [below(values.md)]: {
        marginBottom: `${spacing(1)}px !important`,
      },
    },
    tag_container: {
      padding: "32px !important",
      [below(values.md)]: {
        padding: `${spacing(2)}px !important`,
      },
    },

    // Layout 3
    container_layout_3: {
      ...defaultStyles.container,
      flexWrap: "nowrap !important",
      [below(values.md)]: {
        gap: spacing(2),
      },
    },
    header_layout_3: {
      "& span": {
        fontSize: "32px !important",
        fontWeight: "600 !important",
        lineHeight: "40px !important",
        [below(values.md)]: {
          fontSize: "24px !important",
          lineHeight: "normal !important",
        },
      },
    },
    header_container_layout_3: {
      paddingTop: spacing(3),
      [below(values.sm)]: {
        paddingInline: spacing(1),
      },
    },
    header_container_truncated_layout_3: {
      ...defaultStyles.trucated_text,
      lineClamp: 3,
    },
    media_container_layout_3: {
      width: "100%",
      height: "600px",
      backgroundColor: palette.light.grey,
      maxHeight: "45vh",
      [below(values.md)]: {
        width: "100%",
      },
    },
    image_layout_3: {
      height: "100%",
      width: "100%",
      backgroundSize: "cover",
      backgroundPosition: "top center",
      backgroundRepeat: "no-repeat",
      minHeight: "300px",
    },

    // Layout 4
    container_layout_4: {
      gap: spacing(2),
      ...defaultStyles.container,
      [below(values.sm)]: {
        gap: 0,
      },
    },
    header_layout_4: {
      "& span": {
        fontSize: "24px !important",
        fontWeight: "600 !important",
        lineHeight: "32px !important",
      },
    },
    header_container_truncated_layout_4: {
      ...defaultStyles.trucated_text,
      lineClamp: 3,
    },
    media_container_layout_4: {
      minHeight: "200px",
      maxHeight: "100%",
      position: "relative",
    },
    image_layout_4: {
      height: "100%",
      width: "100%",
      backgroundSize: "cover",
      backgroundPosition: "top center",
      backgroundRepeat: "no-repeat",
    },
    tag_layout_4: {
      position: "absolute",
      backgroundColor: palette.themeColor.primary,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      left: "0px",
      padding: `${spacing(1)}px ${spacing(2)}px`,
      maxWidth: "90%",
      "& span": {
        fontWeight: "600 !important",
        letterSpacing: "1.44px !important",
        textTransform: "uppercase",
      },
    },

    // Layout 5
    container_layout_5: {
      gap: spacing(2),
      ...defaultStyles.container,
      flexWrap: "nowrap !important",
    },
    header_layout_5: {
      "& span": {
        fontSize: "20px !important",
        fontWeight: "700",
        lineHeight: "24px !important",
      },
    },
    header_container_truncated_layout_5: {
      ...defaultStyles.trucated_text,
      lineClamp: 3,
      [below(values.sm)]: {
        padding: `0px ${spacing(1)}px`,
      },
    },
    header_container_layout_5: {
      ...defaultStyles.header_container,
      lineClamp: 3,
      [below(values.sm)]: {
        padding: `0px ${spacing(1)}px`,
      },
    },
    media_container_layout_5: {
      ...defaultStyles.media_container,
      height: "80%",
      [below(1025)]: {
        height: "40vh",
      },
    },
    image_link_layout_5: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    image_layout_5: {
      ...defaultStyles.image,
      maxHeight: "100%",
      maxWidth: "100%",
    },
  };
});

export { useStyles };
